import React from 'react';
import styled, { css } from 'styled-components';
import { StyledBase } from './StyledBase';
import { FontSize, Color, FontWeight } from '../../constants/style-constants';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Button: Used for general button use.
// As more button types are needed, just extend / export them and add the appropriate default props.
// Props:
// All StyledBase props are available.
// onClick: callback triggered when button is clicked
// contentAlign: horizontally aligns text and icon of the button.  Center (default), left and right.
// icon: fontAwesomeIcon that will displayed  It can be left or right aligned.
// iconIsLeft: is icon on the left side of title.  If not, then it will be on the right side.
// iconXOffset: offset added between icon and title.
// iconColor: color of icon.
// iconSize: multiple size by this integer, 2x, 3x, etc
// iconRotation: rotate icon by 90 deg increments. none, 90, 180, 270.
// selected: is button selected or not.  If yes, will set selected color attributes.
// selectedColor, selectedBackgroundColor, selectedIconColor and selectedBoxShadow are set when button selected = true.
// hoverFilter: filter selector used when hovering button.
// hoverTransform: transform selector used when hovering button.

export const ButtonView = styled.button`
	${(props) => css`
		${StyledBase}
	`}
`;

export const StyledFontAwesome = styled(FontAwesomeIcon)`
	${(props) => css`
		margin-left: ${props.marginleft};
		margin-right: ${props.marginright};
		order: ${props.order};
	`}
`;


export const Button = (props) => (
	<ButtonView {...props}>
		{props.icon && (
			<StyledFontAwesome
				className='icon'
				icon={props.icon}
				size={props.iconSize}
				rotation={props.iconRotation}
				marginleft={props.iconIsLeft ? 0 : props.iconXOffset}
				marginright={props.iconIsLeft ? props.iconXOffset : 0}
				order={props.iconIsLeft ? 0 : 1}
				color={
					props.selected ? props.selectedIconColor : props.iconColor
				}
			/>
		)}
		{props.title}
	</ButtonView>
);

Button.defaultProps = {
	fontSize: FontSize.body2,
	color: Color.nile50,
	selectedColor: Color.nile,
	iconIsLeft: 'true',
	iconColor: Color.nile50,
	selectedIconColor: Color.nile,
	selectedBackgroundColor: Color.white,
	backgroundColor: Color.white,
	borderColor: 'transparent',
	display: 'inline-flex',
	alignItems: 'center',
	cursor: 'pointer',
	transitionTime: '0.3s',
	type: 'button'
};

export const SubmitButton = styled(Button)``;
SubmitButton.defaultProps = {
	color: Color.buttonTextOrange,
	textAlign: 'center',
	fontSize: '16px',
	lineHeight: 18 / 16,
	letterSpacing: '0.44px',
	fontWeight: FontWeight.bold,
	backgroundColor: Color.formTitleBlack,
	hoverBackground: Color.signUpTitleBlack,
	textTransform: 'uppercase',
	type: 'submit'
};

export const UnderlineButton = styled(Button)``;
UnderlineButton.defaultProps = {
	color: Color.signUpTitleBlack,
	textAlign: 'center',
	fontSize: '14px',
	fontWeight: FontWeight.bold,
	backgroundColor: 'transparent',
	border: 'transparent',
	textDecorationColor: Color.underLineColor,
	textDecorationStyle: 'dotted',
	textTransform: 'uppercase',
	hoverTextDecorationColor: Color.buttonTextOrange,
	hoverTextDecorationStyle: 'solid',
};
export const ForgotPasswordButton = styled(Button)``;
ForgotPasswordButton.defaultProps = {
	color: Color.formTitleBlack,
	textAlign: 'center',
	fontSize: '14px',
	opacity: '0.5',
	fontWeight: FontWeight.normal,
	backgroundColor: 'transparent',
	border: 'transparent',
	textDecorationStyle: 'solid',
	hoverOpacity: '0.8'
};

export const SetupButton = styled(Button)``;
SetupButton.defaultProps = {
	color: Color.signUpTitleBlack,
	width: '57px',
	height: '32px',
	borderRadius: '2px',
	backgroundColor: 'rgba(0,20,20,0.05)',
	textAlign: 'center',
	fontSize: '14px',
	lineHeight: 16 / 14,
	fontWeight: FontWeight.bold,
	border: 'transparent',
	textTransform: 'uppercase'
};

export const TransparentButton = styled(Button)``;
TransparentButton.defaultProps = {
	color: Color.buttonTextOrange,
	width: '80px',
	height: '18px',
	backgroundColor: 'transparent',
	textAlign: 'center',
	fontSize: '16px',
	lineHeight: 18 / 16,
	fontWeight: FontWeight.bold,
	border: 'transparent',
	textTransform: 'uppercase',
	letterSpacing: '0.44px'
};

export const BackButton = styled(Button)``;
BackButton.defaultProps = {
	color: Color.signUpTitleBlack,
	title: 'back',
	height: '16px',
	backgroundColor: 'transparent',
	fontSize: '14px',
	lineHeight: 16 / 14,
	fontWeight: FontWeight.bold,
	border: 'transparent',
	textTransform: 'uppercase',
	letterSpacing: '0.44px',
	icon: faArrowLeft,
	iconColor: Color.signUpTitleBlack,
	iconXOffset: '8px',
	textAlign: 'left',
	display:'block'

};