import React from 'react';
import { Button, SubmitButton, UnderlineButton, ForgotPasswordButton } from './Button';
import { Input, FormInput } from './Input';
import styled from 'styled-components';
import { StyledText, InputLabelText, FormTitleText } from './StyledText';
import { Dropdown } from './Dropdown';
import { FlexTableController } from './FlexTableController';
import { Color, FontSize } from '../../constants/style-constants';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const KitchenSinkWrapper = styled.div`
`;
const sampleDataArray = [{ 'id': 1, 'name': 'Zagros' }, { 'id': 2, 'name': 'Omar' }, { 'id': 3, 'name': 'Steven' }];
export const KitchenSink = props => {
	return (
		<KitchenSinkWrapper>
			<Button backgroundColor='aqua' color='black' title='Styleable Button' />
			<Input backgroundColor='aqua' color='black' label={<StyledText>Just Style me</StyledText>} />
			<Dropdown
				selectedOption='a'
				options={{ 'a': 'key a', 'b': 'key b', 'c': 'key c', 'd': 'key d' }}
			/>
			<FlexTableController tableData={SetupTableData(sampleDataArray)} />
			<FormInput width='416px' height='48px' label={<InputLabelText>Enter something****</InputLabelText>}/>
			<FormTitleText>hHey</FormTitleText>
			<SubmitButton width='416px' height='50px' title='Submit'/>
			<UnderlineButton title='sign up'/>
			<ForgotPasswordButton title='Forgot password?'/>
		</KitchenSinkWrapper>
	);
};

const SetupTableData = (sampleDataArray) => {

	let tableData = {
		titles: [
			'ID',
			'Name',
			''
		],
		rows: sampleDataArray?.map((item) => {
			return [
				{
					sortValue: item.id,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{item.id}
						</StyledText>
					),
				},
				{
					sortValue: item.id,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{item.name}
						</StyledText>
					),
				},
				{
					element: (
						<Button icon={faDownload} iconColor={Color.nile15} onClick={() => { alert('u clicked me...'); }} />
					)
				},
			];
		}),
	};
	return tableData;
};

