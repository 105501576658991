import React from 'react';
import { DASHBOARD_HEADER_HEIGHT } from '../../constants/style-size-constants';
import styled, { css } from 'styled-components';
import { breakpoint } from '../../projlibs/breakpoints';

const HeaderTabWrapper = styled.div`
	${props => css`
		height: ${DASHBOARD_HEADER_HEIGHT};
		width:147px;
		background-color:${props.bg};
		display:inline-flex;
		align-items:center;
		justify-content:center;
		position:relative;
		margin: ${props.margin};
		float: ${props.float};
		${breakpoint('small only')}{
			margin: ${props.smallMargin};
		}
	`}
`;
const BorderBottom = styled.div`
	height:8px;
	width:100%;
	position:absolute;
	bottom:0;
	float:bottom;
	background: linear-gradient(270deg, #FFBF3D 0%, #F7921E 100%);
`;
export const HeaderTab = props => {
	return (
		<HeaderTabWrapper float={props.float} smallMargin={props.smallMargin} margin={props.margin} bg={props.bg}>
			{props.content}
			{props.selected && <BorderBottom />}
		</HeaderTabWrapper>
	);
};