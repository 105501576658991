import React from 'react';
import styled from 'styled-components';
import { SteelixTextBold } from '../Shared/StyledText';
export const Wrapper = styled.div`
	width:100%;
    align-items:center;
    justify-content:center;
    margin-top: 32px;
`;

export const TransactionError = ({ title, description }) => {
    return (
        <Wrapper>
            <SteelixTextBold horizontalAlign="center" fontSize="32px" marginBottom="32px">
                {title}

            </SteelixTextBold>
            {description}
        </Wrapper>
    )
}
