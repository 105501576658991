import React from 'react';
import styled, { css } from 'styled-components';
import { DueText, DueTextValue } from '../Shared/StyledText';
import { Color, FontWeight } from '../../constants/style-constants';
import CurrencyFormat from 'react-currency-format';

const PaymentSummaryWrapper = styled.div`
`;

const RowWrapper = styled.div`
	display:flex;
	justify-content:space-between;
	margin-bottom:16px;
	${props => css`
		align-items:${props.alignItems};
		flex-wrap: ${props.flexWrap};
	`}
	.currency{
		background-color: ${Color.inputBG};
		border-radius:2px;
		height:48px;
		width:100%;
		border: 1px solid ${Color.inputBorder};
		font-size: 24px;
	}
`;


const CurrencyWrapper = styled.div`
	width:100%;
	display:flex;
	align-items:center;
`;

const FlexWrapper = styled.div`
	display:flex;
	align-items:center;
	width:auto;
	/* flex-wrap:wrap; */
	&:first-child{
		width:75%;
	}
`;
export const PaymentSummary = props => {
	if (props.loading) {
		return <></>
	}
	return (
		<PaymentSummaryWrapper>
			<RowWrapper>
				<DueTextValue textAlign='left'>Dues Payment Amount:</DueTextValue>
			</RowWrapper>
			<RowWrapper flexWrap='wrap' alignItems='center'>
				<CurrencyWrapper>
					<FlexWrapper>
						<CurrencyFormat decimalScale={2} thousandSeparator={true} prefix={'$'} allowNegative={false} value={props.amount} required={true} className='currency' onValueChange={props.valueChange} /><DueText fontSize='24px' marginLeft='8px' fontWeight={FontWeight.bold}>CAD</DueText>
					</FlexWrapper>

				</CurrencyWrapper>
			</RowWrapper>
		</PaymentSummaryWrapper>
	);
};
