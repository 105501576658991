import styled, { css } from 'styled-components';
import React, { useState, useContext, useEffect } from 'react';
import { FormInput } from '../Shared/Input';
import { InputLabelText, DueTextValue, SteelixText, DueText } from '../Shared/StyledText';
import { Dropdown } from '../Shared/Dropdown';
import { breakpoint } from '../../projlibs/breakpoints';
import { SubmitButton } from '../Shared/Button';
import { Color } from '../../constants/style-constants';
import { DataContext } from '../../data-context';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { error, success } from '../../projlibs/feedback';
import { API } from '../../projlibs/api';
import { NAV_LOGIN } from '../../constants/nav-constants';
import { CARD_BRANDS } from '../../constants/general-constants';
import { config } from '../../config';
import { parse_cookies } from '../../projlibs/cookie-management';
import { UNION_NUMBER } from '../../constants/cookie-constants';
import moment from 'moment';
import { customAlphabet } from 'nanoid'
import { clearCookies } from '../../react-utils/src/helperfunctions';
const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 10)
const MakePaymentFormWrapper = styled.form`
	box-sizing:border-box;

	#checkout-form {
		position: relative;
		width: 95%;
		margin: auto;
		padding: 10px;
	}

	#checkout-form label {
		display: block;
		min-height: 25px;
		font-size: 15px;
		font-weight: 500;
		margin: 5px 0;
		padding: 0;
		color: red;
	}

	#card-number, #card-cvv, #card-expiry {
		background-color: #FFF;
		display: block;
		box-sizing:border-box;
		width: 100%;
		border-radius: 2px;
		border: 1px solid rgba(200, 200, 200, 1);
		padding: 14px 60px 13px 20px;
		margin: auto;
		transition: all 100ms ease-out;
		background-color: ${Color.inputBG};
		border-radius: 2px;
		border: 1px solid ${Color.inputBorder};
		margin-bottom:8px;
		height: 48px;
		font-size:16px;
	}

	/* card images are added to card number */
	#card-number {
		background-image: none;

		background-origin: content-box;
		background-position: calc(100% + 40px) center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	/* buttons */
	.btn {
		vertical-align: top;
	}

	.btn {
		position: relative;
		border: none;
		border-radius: 4px;
		background-color: rgba(120, 71, 181, 1);
		color: rgba(255, 255, 255, 1);
		display: inline-block;
		transition: all 100ms ease-out;
		padding: 11px 25px;
	}

	.btn:hover, .btn:active {
		background-color: rgba(69, 36, 89, 1);
	}

	.btn:active {
		box-shadow: inset 0 0 35px rgba(0, 0, 0, 0.3);
	}

	.btn:disabled {
		background-color: rgba(255, 255, 255, 1);
		border: 1px solid rgba(120, 71, 181, 1);
		color: rgba(120, 71, 181, 1);
	}

	/* feedback is displayed after tokenization */
	#feedback {
		position: relative;
		left: 15px;
		display: inline-block;
		background-color: transparent;
		border: 0px solid rgba(200, 200, 200, 1);
		border-radius: 4px;
		transition: all 100ms ease-out;
		padding: 11px;
	}

	#feedback.error {
		color: red;
		border: 1px solid;
	}

	#feedback.success {
		color: seagreen;
		border: 1px solid;
	}
`;

const CardRowWrapper = styled.div`
${props => css`
		display: flex;
		flex-direction:row;
		margin:${props.margin};
		position:${props.position};
		height: ${props.height};
		align-items: ${props.alignItems};
		justify-content: ${props.justifyContent ? props.justifyContent : 'space-between'};
		${breakpoint('small only')}{
			flex-direction:${props.smallFlexDirection ? props.smallFlexDirection : 'column'};
		}
	`};
`;
const RowWrapper = styled.div`
${props => css`
		display: flex;
		margin:${props.margin};
		position:${props.position};
		height: ${props.height};
		align-items: ${props.alignItems};
		justify-content: ${props.justifyContent ? props.justifyContent : 'space-between'};
		${breakpoint('small only')}{
			flex-direction:${props.smallFlexDirection ? props.smallFlexDirection : 'column'};
		}
	`};
`;
const DoubleItemRow = styled.div`
	display:flex;
	box-sizing:border-box;
	&:first-child {
		padding-left: 0;
	}
`;

const LabelWrapper = styled.div`
	width:100%;
	${props => css`
		padding: ${props.padding};
	`};
`;
const provinces = {
	'AB': 'Alberta',
	'BC': 'British Columbia',
	'MB': 'Manitoba',
	'NB': 'New Brunswick',
	'NL': 'Newfoundland and Labrador',
	'NT': 'Northwest Territories',
	'NS': 'Nova Scotia',
	'NU': 'Nunavut',
	'ON': 'Ontario',
	'PE': 'Prince Edward Island',
	'QC': 'Quebec',
	'SK': 'Saskatchewan',
	'YT': 'Yukon Territory'
};
export const MakePaymentForm = props => {
	const history = useHistory();
	const context = useContext(DataContext);
	const [cardType, setCardType] = useState('');
	const [orderNumber,] = useState(nanoid(10))
	const [input, setInput] = useState({
		'address_line2': '',
		'city': '',
		'province': '',
		'postal_code': '',
		'name': '',
		'email_address': '',
		'address_line1': ''
	});
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		if (context['bamboraInputs'].length === 3) {
			context['bamboraInputs'][0].mount('#card-number');
			context['bamboraInputs'][1].mount('#card-expiry');
			context['bamboraInputs'][2].mount('#card-cvv');
			window.customCheckout.on('brand', function (event) {
				setCardType(event.brand);
				var cardLogo = 'none';
				if (event.brand && event.brand !== 'unknown') {
					var filePath =
						'https://cdn.na.bambora.com/downloads/images/cards/' +
						event.brand +
						'.svg';
					cardLogo = 'url(' + filePath + ')';
				}
				document.getElementById('card-number').style.backgroundImage = cardLogo;
			});
		} else {
			var style = {
				error: {
					color: 'red',
					':focus': {
						fontStyle: 'italic',
					},
				},
			};
			// Classes to be applied to the element wrapping the iframe
			var classes = {
				error: 'my-error-class',
			};

			let card = window.customCheckout.create('card-number', {
				placeholder: 'Visa / Mastercard Number',
				style: style,
				classes: classes,
				brands: CARD_BRANDS,
			});
			card.mount('#card-number');
			let expiry = window.customCheckout.create('expiry', {
				placeholder: 'MM / YY',
				style: style,
				classes: classes,
			});
			expiry.mount('#card-expiry');
			let cvc = window.customCheckout.create('cvv', {
				placeholder: 'CVV',
				style: style,
				classes: classes,
			});
			cvc.mount('#card-cvv');
			window.customCheckout.on('brand', function (event) {
				setCardType(event.brand);
				var cardLogo = 'none';
				if (event.brand && event.brand !== 'unknown') {
					var filePath =
						'https://cdn.na.bambora.com/downloads/images/cards/' +
						event.brand +
						'.svg';
					cardLogo = 'url(' + filePath + ')';
				}
				document.getElementById('card-number').style.backgroundImage = cardLogo;
			});
			context.setBamboraInputs([card, expiry, cvc]);
		}
	}, [context]);
	const handleInputChange = (e) => {
		e.preventDefault();
		if (e.currentTarget.id) {
			let newInput = { ...input };
			newInput[e.currentTarget.id] = String(e.currentTarget.value);
			setInput(newInput);
		}
	};
	const submit = (e) => {
		e.preventDefault();
		if (props.duplicateTsx || props.liunaError || props.loading) {
			return false;
		}
		props.setLoading(true);
		let cookies = parse_cookies();
		if (!CARD_BRANDS.includes(cardType)) {
			error('Card type is not supported. Please enter a valid Visa or Mastercard.');
			window.DD_LOGS.logger.error('Error: Card type is not supported.', { union_number: cookies[UNION_NUMBER], env: config.dd_env, cardType: cardType });
			props.setLoading(false);
			return false;
		}
		input['amount'] = props.amountToPay;
		input['province'] = document.getElementById('province').value;// this is because the auto complete is not triggering handleinputchange for dropdown
		window.customCheckout.createToken(function (result) {
			if (result.error) {
				error(result.error.message);
				window.DD_LOGS.logger.error('Error: Bambora Create Token Error: ' + result.error.message, { union_number: cookies[UNION_NUMBER], env: config.dd_env, input: input, message: result.error.message });
				props.setLoading(false);
				return false;
			} else {
				input['token'] = result.token;
				input['card_type'] = cardType;
				input['order_number'] = orderNumber;
				API.post('/payments', { data: input }).then(({ data }) => {
					success('Payment successfully processed');
					props.setPaymentSuccess(true);
				}).catch(err => {
					if (err.response) {
						if (err.response.status === 401) {
							error("Login session has expired. Please log in again.")
							clearCookies();
							history.push(NAV_LOGIN);
							return
						}
						if (err.response.status === 402) {
							error('Duplicate Transaction - This transaction has already been approved. If it has not been recorded please contact support')
							if (err?.response?.data?.detail) {
								const { detail } = err.response.data;
								if (detail) {
									props.setErrorInfo({ order_number: detail.order_number, amount: detail.amount })
								}
							}
							props.setDuplicateTsx(true)
							return
						}
						if (err.response.status === 400 && !err?.response?.data?.detail?.bambora_error) {
							// payment went through but liunas api failed to record the transaction
							if (err?.response?.data?.detail) {
								const { detail } = err.response.data;
								props.setErrorInfo({ amount: detail.amount, created_at: moment(detail.created_at).format('DD/MM/YYYY hh:mm:ssA'), order_number: detail.order_number })
								props.setLiunaError(true)
							} else {
								props.setLiunaError(true)
							}
							return
						}
					}

					if (err && err.response && err.response.data) {
						window.DD_LOGS.logger.error('Error: payment could not process after creating Bambora Token. ' + err?.response?.data, { union_number: cookies[UNION_NUMBER], env: config.dd_env, input: input, message: err?.response?.data, status: err?.response?.status });
					} else {
						if (err.response) {
							window.DD_LOGS.logger.error('Error: payment could not process after creating Bambora Token. err.response.data field was unrecognized displaying entire err.response. ' + JSON.stringify(err.response), { union_number: cookies[UNION_NUMBER], env: config.dd_env, input: input, message: err.response, status: err?.response?.status });
						} else {
							window.DD_LOGS.logger.error('Error: payment could not process after creating Bambora Token. err.response field was unrecognized displaying entire error. ' + JSON.stringify(err), { union_number: cookies[UNION_NUMBER], env: config.dd_env, input: input, message: err, status: err?.response?.status });
						}
					}
					if(err?.response?.data?.detail?.text){
						error('Payment could not be processed. '+ err?.response?.data?.detail?.text);
						props.setLoading(false);
					}else{
						error('Payment could not be processed.');
					}
					
				});
			}
		});
	};
	return (
		<>

			<DueTextValue display='block'>Billing Address</DueTextValue>
			<SteelixText display='block'>Please enter your billing address.</SteelixText>
			<MakePaymentFormWrapper key='checkout-form' id='checkout-form' className='MakePaymentFormWrapper' onSubmit={submit}>
				<RowWrapper className='row-wrapper'>
					<FormInput display='inline-block' width='65%' onChange={handleInputChange} inputId='address_line1' height='48px' label={<InputLabelText>Street*</InputLabelText>} />
					<FormInput required={false} display='inline-block' width='33%' onChange={handleInputChange} inputType='number' inputId='address_line2' height='48px' label={<InputLabelText>Apt./Unit #</InputLabelText>} />
				</RowWrapper>

				<RowWrapper className='row-wrapper'>
					<FormInput display='inline-block' width='49%' onChange={handleInputChange} inputId='city' height='48px' label={<InputLabelText>City*</InputLabelText>} />

					<Dropdown marginBottom='8px' smallWidth='100%' onChange={handleInputChange} selectId='province' label={<InputLabelText>Province*</InputLabelText>} selectedOption='ON' width='49%' height='48px' options={provinces} />
				</RowWrapper>

				<RowWrapper className='row-wrapper'>
					<FormInput display='inline-block' width='49%' onChange={handleInputChange} inputId='postal_code' height='48px' label={<InputLabelText>Postal Code*</InputLabelText>} />
					<FormInput display='inline-block' width='49%' onChange={handleInputChange} inputId='email_address' height='48px' label={<InputLabelText>Email*</InputLabelText>} />
				</RowWrapper>
				<RowWrapper>
					<FormInput display='inline-block' width='100%' onChange={handleInputChange} inputId='phone_number' inputType='tel' height='48px' label={<InputLabelText>Phone Number*</InputLabelText>} />
				</RowWrapper>
				<DueTextValue marginTop='32px' display='block'>Payment Details</DueTextValue>
				<SteelixText display='block'>Please add your credit card</SteelixText>

				<RowWrapper className='row-wrapper'>
					<FormInput marginTop='16px' marginBottom='8px' display='inline-block' onChange={handleInputChange} inputId='name' height='48px' label={<InputLabelText>Full Name*</InputLabelText>} />
				</RowWrapper>
				<CardRowWrapper>
					<LabelWrapper padding='8px 8px 0 0'>
						<InputLabelText>Credit Card Number</InputLabelText>
						<div id="card-number" className="form-control"></div>
					</LabelWrapper>

					<DoubleItemRow>
						<LabelWrapper padding='8px'>
							<InputLabelText>Expiry Date (MM/YY)</InputLabelText>
							<div id="card-expiry"></div>
						</LabelWrapper>
						<LabelWrapper padding='8px 0 0 8px'>
							<InputLabelText>CVC</InputLabelText>
							<div id="card-cvv"></div>
						</LabelWrapper>

					</DoubleItemRow>
				</CardRowWrapper>
				<hr />
				<RowWrapper alignItems='center' smallFlexDirection='row' margin='32px 0 0 0'>
					<DueText >You are paying:</DueText>
					<DueTextValue>{`$${props.amountToPay} CAD`}</DueTextValue>
				</RowWrapper>

				<RowWrapper margin='32px 0 0 0' height='50px' position='relative' className='row-wrapper'>
					<SubmitButton disabled={props.loading || props.amountToPay === 0} iconXOffset={'16px'} iconIsLeft={0} iconColor={Color.buttonTextOrange} icon={faLock} left='0' bottom='0' position='absolute' width='100%' height='50px' title='pay dues' />
				</RowWrapper>
				<RowWrapper margin='16px 0 48px 0' justifyContent='center'>
					<SteelixText fontSize='14px' lineHeight={18 / 14}>Clicking "Pay Dues" will securely process your payment.</SteelixText>
				</RowWrapper>
			</MakePaymentFormWrapper>
		</>
	);
};
