export const config = {
        theme: '',
	login_url: 'https://dues.liunalocal1059.com/login',
	dd_service: 'younion-payment-client',
	dd_client_token: 'pubfc9ce7364c6033d1f7256dfe3fa30c09',
	dd_env: 'liuna-prod',
	login_token_expiration_seconds: 3600,
        //axios networking configuration
        axios:{
                // `baseURL` will be prepended to `url` unless `url` is absolute.
                // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
                // to methods of that instance.
                //backend server
//                baseURL: 'https://api.liuna1059.rhinoactive.com/api',
                baseURL: 'https://api.dues.liunalocal1059.com/api',
                // `headers` are custom headers to be sent
                headers: {'Authorization':''},
                // `timeout` specifies the number of milliseconds before the request times out.
                // If the request takes longer than `timeout`, the request will be aborted.
                timeout: 5000, // default is `0` (no timeout)
                // `withCredentials` indicates whether or not cross-site Access-Control requests
                // should be made using credentials
                withCredentials: true,
                // `responseType` indicates the type of data that the server will respond with
                // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
                //       browser only: 'blob'
                responseType: 'json', // default
                // `responseEncoding` indicates encoding to use for decoding responses
                // Note: Ignored for `responseType` of 'stream' or client-side requests
                responseEncoding: 'utf8', // default
                // `maxContentLength` defines the max size of the http response content in bytes allowed
                maxContentLength: null,
                // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
                maxBodyLength: null,
        },
};

