import { toast } from 'react-toastify';
import { usePsuedoConstructor } from '../custom-hooks/usePsuedoConstructor';
import { API } from '../projlibs/api';
import React from 'react';
import styled from 'styled-components';
import { parse_cookies } from '../projlibs/cookie-management';
import { ACCESS_TOKEN } from '../constants/cookie-constants';
import { useHistory } from 'react-router-dom';
const Wrapper = styled.div`
	height:calc(100vh - 150px);
	display:flex;
	align-items:center;
	justify-content:center;
`;
export const ResetTestPayments = props =>{
	let cookies = parse_cookies();
	const history = useHistory();
	usePsuedoConstructor(()=>{
		API.get('/reset-test-payment').then(data=>{
			toast.success('Test Credentials have been reset. Redirecting.');
			history.goBack();
		}).catch(API.default_error_handler);
	});
	let page_string = 'Resetting Test Credentials.';
	if (!cookies[ACCESS_TOKEN]){
		page_string = 'Please login before attempting to reset your dues.'
	}
	return (<Wrapper>
		{page_string}
	</Wrapper>);
};
