import React from 'react';
import { FormTitleText, InputLabelText } from '../Shared/StyledText';
import { FormInput } from '../Shared/Input';
import { SubmitButton, UnderlineButton, ForgotPasswordButton } from '../Shared/Button';
import styled from 'styled-components';
import { config } from '../../config';
const Wrapper =styled.div`
	a{
		text-decoration: none;
		display:block;
	}
`;
export const LoginFormRender = props =>{
	return (<Wrapper>
		<FormTitleText marginBottom='32px'>Please Login</FormTitleText>
		<FormInput onChange={props.handleInputChange} inputId='username' height='48px' label={<InputLabelText>Member Number (found on Member Card) / Email</InputLabelText>}/>
		<FormInput onChange={props.handleInputChange} inputType='password' marginBottom='32px' inputId='password'  height='48px' label={<InputLabelText>Password*</InputLabelText>}/>
		<SubmitButton width='100%' marginBottom='32px' onClick={props.submit} type='submit' height='50px' title='login'/>
		<a target="_blank" rel='noopener noreferrer' href={`https://members.liunalocal1059.com/Login/Register?redirect_to=${config.login_url}`}><UnderlineButton marginBottom='32px'title='sign up'/></a>
		<a target="_blank" rel='noopener noreferrer' href="https://members.liunalocal1059.com/Login/ForgotPassword"><ForgotPasswordButton title='Forgot password?'/></a>
	</Wrapper>);
};