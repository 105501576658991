import React from 'react';
import { SteelixTextBold } from '../Shared/StyledText';

export const PaymentSuccess = () => {
    return (
        <div>
            <SteelixTextBold horizontalAlign="center" fontSize="32px" marginBottom="32px">
                Payment was successful
            </SteelixTextBold>
        </div>
    )
}