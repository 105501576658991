import React from 'react';
import styled from 'styled-components';
import {  SubmitButton } from '../Shared/Button';
import { breakpoint } from '../../projlibs/breakpoints';
import { useHistory } from 'react-router-dom';
import { NAV_MAKE_PAYMENT } from '../../constants/nav-constants';

const Wrapper = styled.div`
	width: 306px;
	height:fit-content;
	box-sizing: border-box;
	margin-left:32px;
	margin-top:32px;
	${breakpoint('small only')}{
		margin:32px 0 32px 0;
		padding: 0px 32px;
		width:100%;
	}
`;


export const MakePaymentBox = props => {
	const history = useHistory();
	return (
		<Wrapper className='make-payment-box-wrapper'>
			<SubmitButton 
				onClick={e => { e.preventDefault(); history.replace(NAV_MAKE_PAYMENT); }} 
				left='0' bottom='0' width='100%' height='50px' title='make payment' 
			/>
		</Wrapper>
	);
};
