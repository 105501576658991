// COOKIE STRINGS
export const AWS_IDENTITY_ID = 'AWSToken.IdentityId';
export const AWS_TOKEN = 'AWSToken.Token';
export const SESSION_ID = 'session_id';
export const USER_ID = 'user_id';
export const EMAIL = 'email';
export const COOKIE_ISSUED_AT = '.issued';
export const ACCESS_TOKEN = 'access_token';
export const LOGIN_EXPIRES_IN = 'expires_in';
export const LOGIN_TOKEN_TYPE = 'token_type';
export const UNION_NUMBER = 'union_num';
export const USERNAME = 'userName';