import React, { useState } from 'react';
import styled from 'styled-components';
import { FormWrapper } from '../Shared/Containers/FormWrapper';
import { PageWrapper } from '../Shared/Containers/PageWrapper';
import { Image } from '../Shared/Image';
import { breakpoint } from '../../projlibs/breakpoints';
import { SignUpFormRender } from './SignUpFormRender';
import { LoginFormRender } from './LoginFormRender';
import { useLocation, useHistory } from 'react-router-dom';
import { NAV_SIGN_UP, NAV_LOGIN, NAV_DASHBOARD } from '../../constants/nav-constants';
import { Color } from '../../constants/style-constants';
import { API } from '../../projlibs/api';
import { set_cookie } from '../../projlibs/cookie-management';
import {
	UNION_NUMBER,
	USERNAME,
	LOGIN_TOKEN_TYPE,
	LOGIN_EXPIRES_IN,
	ACCESS_TOKEN,
	COOKIE_ISSUED_AT
} from '../../constants/cookie-constants';
import login_image from '../../assets/hero_about-resized-4k-center-cropped.jpg';
import logo from '../../assets/liuna_logo.jpeg';
import { config } from '../../config';
const LoginPageSplit = styled.div`
	display:flex;
	height:100vh;
	width:100%;
	${breakpoint('small only')} {
		justify-content:center;
		align-items:center;
	}
`;
const ImagePortion = styled.div`
	height:100%;
	width:35%;
	${breakpoint('small only')} {
		display:none;
	}
`;
const ContentPortion = styled.div`
	height:100%;
	width:65%;
	background-color: ${Color.white};
	display:flex;
	align-items:center;
	${breakpoint('small only')} {
		width:100%;
	}
`;
const LoginForm = props => {
	const location = useLocation();
	const history = useHistory();
	const [input, setInput] = useState({ 'username': '', 'password': '', 'grant_type': 'password' });
	const handleInputChange = (e) => {
		e.preventDefault();
		if (e.currentTarget.id) {
			let newInput = { ...input };
			newInput[e.currentTarget.id] = e.currentTarget.value;
			setInput(newInput);
		}
	};
	const submit = (e) => {
		e.preventDefault();
		API.post('/login', { data: input }, false).then(({ data }) => {
			let cookieExpirationDuration = new Date();
			cookieExpirationDuration.setTime(cookieExpirationDuration.getTime() + 1 * config.login_token_expiration_seconds * 1000);
			set_cookie(COOKIE_ISSUED_AT, data['.issued']);
			set_cookie(ACCESS_TOKEN, data['access_token'], cookieExpirationDuration);
			set_cookie(LOGIN_EXPIRES_IN, data['expires_in']);
			set_cookie(LOGIN_TOKEN_TYPE, data['token_type']);
			set_cookie(UNION_NUMBER, input['username']);
			set_cookie(USERNAME, data['userName']);
			history.push(NAV_DASHBOARD);
		}).catch(err => {
			// note that we need custom logging to keep track of what params are sent instead of
			// just tracking response data
			window.DD_LOGS.logger.error('Login Failed', { union_number: input['username'] });
			API.default_error_handler(err);
		});
	};
	const renderFormContents = () => {
		switch (location.pathname) {
			case NAV_SIGN_UP:
				return <SignUpFormRender submit={submit} handleInputChange={handleInputChange} />;
			case NAV_LOGIN:
				return <LoginFormRender submit={submit} handleInputChange={handleInputChange} />;
			default:
				return <LoginFormRender submit={submit} handleInputChange={handleInputChange} />;
		};
	};
	return (
		<FormWrapper onSubmit={submit} marginLeft='64px' width='416px' className='form-wrapper'>
			{renderFormContents()}
		</FormWrapper>
	);

};

const LogoContainer = styled.div`
	position:absolute;
	width:164px;
	height:112px;
	left:64px;
	background-color:white;
	display:flex;
	align-items:center;
	justify-content:center;
	${breakpoint('small only')} {
		display:none;
	}
`;
export const Login = props => {
	return (
		<PageWrapper className='page-wrapper'>
			<LoginPageSplit className='login-split'>
				<LogoContainer>
					<Image src={logo} />
				</LogoContainer>
				<ImagePortion className='image-half'>
					<Image
						src={login_image}
						width='100%'
						height='100%'
					/>
				</ImagePortion>

				<ContentPortion>
					<LoginForm />
				</ContentPortion>
			</LoginPageSplit>
		</PageWrapper>
	);
};
