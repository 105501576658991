import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Color, FontSize } from '../../constants/style-constants';
import { breakpoint } from '../../projlibs/breakpoints';
import { DashboardTableTitle, StyledText } from '../Shared/StyledText';
import { FlexTableController } from '../Shared/FlexTableController';
import { API } from '../../projlibs/api';
import { PageController } from '../Shared/PageController';
import { updateOrderPaymentHistory } from '../../projlibs/tablesorting';
import { DATE, DESCRIPTION, AMOUNT } from '../../constants/table-constants';
import moment from 'moment';
import { parse_cookies } from '../../projlibs/cookie-management';
import { UNION_NUMBER } from '../../constants/cookie-constants';
export const CardWrapper = styled.div`
	background-color:${Color.white};
	height: 100%;
	min-height: 617px;
	box-sizing:border-box;
	border: 1px solid #E8EAEA;
	border-radius: 8px;
	${props => css`
		width:${props.width};
		padding: ${props.padding};
		margin-bottom: ${props.marginBottom};
	`}
	${breakpoint('small only')} {
		width:100%;
	}
`;
const updateSorting = (e, title, setter) => {
	setter(updateOrderPaymentHistory(e, title));
	e.currentTarget.dataset.sortorder = e.currentTarget.dataset.sortorder === 'asc' ? 'desc' : 'asc';
};

export const DashboardCard = props => {
	const [page, setPage] = useState(1);
	const [pageSize,] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [orderBy, setOrderBy] = useState('s_field=paidon&s_order=D');
	const [paymentHistory, setPaymentHistory] = useState([{}]);
	useEffect(() => {
		API.get(`/payments/history?page=${page}&page_size=${pageSize}&${orderBy}`).then(({ data }) => {
			data['Payments'] = data['Payments'].map((item, index) => {
				let date = new Date(data['Payments'][index]['PaidOn']);
				data['Payments'][index]['formatted_data'] = moment(date).format('DD/MM/YYYY');
				return data['Payments'][index];
			});
			setPaymentHistory(data['Payments']);
			setTotalPages(data.TotalPages);
		}).catch(err => {
			let cookies = parse_cookies();
			// note that any response will be logged inside API.default_error_handler and forwarded to datadog
			// this is just to save request params
			window.DD_LOGS.logger.error('Get payment history failed.', { union_number: cookies[UNION_NUMBER] });
			API.default_error_handler(err);
		});
	}, [page, pageSize, orderBy]);
	return (
		<CardWrapper width='70%' className='card-wrapper'>
			<DashboardTableTitle>Payment History</DashboardTableTitle>
			<FlexTableController tableData={SetupTableData(paymentHistory)} sort={(e, title) => updateSorting(e, title, setOrderBy)} />
			<PageController pageNumber={page} setPageNumber={setPage} numPages={totalPages} />
		</CardWrapper>

	);
};

const SetupTableData = (paymentHistory) => {

	return ({
		titles: [
			DATE,
			DESCRIPTION,
			AMOUNT
		],
		rows: paymentHistory?.map((item) => {
			return [
				{
					sortValue: item?.PaidOn,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{item?.formatted_data}
						</StyledText>
					),
				},
				{
					sortValue: item?.Description,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{item?.Description}
						</StyledText>
					),
				},
				{
					sortValue: item?.AmountPaid,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							${item?.AmountPaid} CAD
						</StyledText>
					),
				},
			];
		}),
	});
};