import React from 'react';
import styled from 'styled-components';
import { Image } from '../Shared/Image';
import { breakpoint } from '../../projlibs/breakpoints';
import { DASHBOARD_HEADER_HEIGHT } from '../../constants/style-size-constants';
import { HeaderTab } from './HeaderTab';
import { DashBoardHeaderText } from '../Shared/StyledText';
import { Color } from '../../constants/style-constants';
import { TransparentButton } from '../Shared/Button';
import { NAV_LOGIN } from '../../constants/nav-constants';
import { useHistory } from 'react-router-dom';
import { clearCookies } from '../../react-utils/src/helperfunctions';
import logo from '../../assets/liuna_logo.jpeg';
const DashBoardHeaderContainer = styled.div`
	height: ${DASHBOARD_HEADER_HEIGHT};
	width:100%;
	background: linear-gradient(270deg, #1E3131 0%, #001414 100%);
	position:fixed;
	display:flex;
	z-index:15;
`;
export const LogoContainer = styled.div`
	height: ${DASHBOARD_HEADER_HEIGHT};
	width: 121px;
	margin-left:10%;
	background-color:white;
	display:flex;
	align-items:center;
	justify-content:center;
	${breakpoint('small only')} {
		margin-left:0;
	}
`;
export const DashboardHeader = props => {
	const history = useHistory();
	const logout = (e) => {
		e.preventDefault();
		clearCookies();
		history.push(NAV_LOGIN);
	};
	return (
		<DashBoardHeaderContainer className='dashboard-header-container'>
			<LogoContainer>
				<Image src={logo} />
			</LogoContainer>
			<HeaderTab smallMargin='0' margin='0 0 0 96px' bg={Color.mamba} selected={true} content={<DashBoardHeaderText>Dues Dashboard</DashBoardHeaderText>} />
			<HeaderTab
				smallMargin='0 0 0 auto'
				margin='0 10% 0 auto'
				float='right'
				selected={false}
				bg='transparent'
				content={<TransparentButton onClick={logout} title='logout' />}
			/>
		</DashBoardHeaderContainer>
	);
};