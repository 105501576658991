import styled, { css } from 'styled-components';
import { breakpoint } from '../../../projlibs/breakpoints';
export const FormWrapper = styled.form.attrs(props => ({
		className: props.className
	}))`
	${props => css`
		width:${props.width};
		height: ${props.height};
		margin-left: ${props.marginLeft};
		display:flex;
		flex-direction:column;
		${breakpoint('small only')} {
			width:90%;
			margin:auto;
			align-items:center;
			display:flex;
		}
	`}
`;