import React, { useState } from 'react';
import './App.css';
import { DataContext } from './data-context';
import { Helmet } from 'react-helmet';
import { GlobalStyle } from './constants/style-constants';
import { MainTheme } from './theme';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { RootView } from './components/RootView/RootView';
import { withRouter } from 'react-router-dom';
import { usePsuedoConstructor } from './custom-hooks/usePsuedoConstructor';
import 'react-toastify/dist/ReactToastify.css';
import { config } from './config';


const App = props => {
	// a psuedo custom constructor hook
	const [dataContext, setContext] = useState({ ...DataContext._currentValue });

	usePsuedoConstructor(() => {
		// init datadog
		window.DD_LOGS.init({
			clientToken: config.dd_client_token,
			forwardErrorsToLogs: true,
			site: 'datadoghq.com',
			env: config.dd_env,
			sampleRate: 100,
			service: config.dd_service
		});
		const setBamboraInputs = data=>{
			setContext((prev) => ({
				...prev, bamboraInputs: data
			}));
		};
		setContext((prevContext) => ({
			...prevContext,
			setBamboraInputs,
		}));
	});
	return (
		<DataContext.Provider value={dataContext}>
			<div className='App'>
				<Helmet>
					Dues Policy - http://liuna1059.wpengine.com/dues-policy/
					<link
						href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap'
						rel='stylesheet'
					/>
				</Helmet>
				<GlobalStyle />
				<ThemeProvider theme={MainTheme}>
					<ToastContainer />
					<RootView />
				</ThemeProvider>
			</div>
		</DataContext.Provider>
	);
};
export default withRouter((props) => <App {...props} />);
