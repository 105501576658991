import { DATE, AMOUNT, DESCRIPTION } from '../constants/table-constants';
export const updateOrderPaymentHistory = (e, title) => {
	let sort_order = e.currentTarget.dataset.sortorder.charAt(0).toUpperCase();
	switch(title){
		case DATE:
			return `s_field=paidon&s_order=${sort_order}`;
		case AMOUNT:
			return `s_field=dues&s_order=${sort_order}`;
		case DESCRIPTION:
			return `s_field=duesdesc&s_order=${sort_order}`;
		default:
			return '';
	};
};