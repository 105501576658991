import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import {
    NAV_LOGIN,
    NAV_KITCHEN,
    NAV_SIGN_UP,
    NAV_DASHBOARD,
    NAV_MAKE_PAYMENT,
    NAV_RESET
} from '../../constants/nav-constants';
import { Login } from '../Login/Login';
import { KitchenSink } from '../Shared/KitchenSink';
import { Dashboard } from '../Dashboard/Dashboard';
import { MakePayment } from '../MakePayment/MakePayment';
import { Footer } from '../Footer';
import { ResetTestPayments } from '../ResetTestPayments';

export const RootViewWrapper = styled.div`
`;
export const RootView = props => {
	return (
		<RootViewWrapper className='root-view-wrapper'>
			<Switch>
				<Route
					exact path='/'
					render={() => <Login {...props} />}
				/>
				<Route
					path={NAV_DASHBOARD}
					render={() => <Dashboard {...props} />}
				/>
				<Route
					path={NAV_MAKE_PAYMENT}
					render={() => <MakePayment {...props} />}
				/>
				<Route
					path={NAV_LOGIN}
					render={() => <Login {...props} />}
				/>
				<Route
					path={NAV_SIGN_UP}
					render={() => <Login {...props} />}
				/>
				<Route
					path={NAV_KITCHEN}
					render={() => <KitchenSink {...props} />}
				/>
				<Route
					path={NAV_RESET}
					render={() => <ResetTestPayments {...props} />}
				/>
			</Switch>
			<Footer/>
		</RootViewWrapper>
	);
};
