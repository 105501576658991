import React, { useEffect } from 'react';
import { DashboardHeader } from './DashboardHeader';
import { PageWrapper } from '../Shared/Containers/PageWrapper';
import styled from 'styled-components';
import { DashboardCard } from './DashboardCard';
import { breakpoint } from '../../projlibs/breakpoints';
import { DASHBOARD_HEADER_HEIGHT } from '../../constants/style-size-constants';
import { MakePaymentBox } from './MakePaymentBox';
import {useLocation} from "react-router-dom";
import {set_cookie} from "../../projlibs/cookie-management";
import {ACCESS_TOKEN} from "../../constants/cookie-constants";

export const PageContent = styled.div`
	margin: ${DASHBOARD_HEADER_HEIGHT} 10% 0 10%;
	display:inline-flex;
	width:80%;
	height:100%;
	padding-top:32px;
	flex-direction: row-reverse;
	${breakpoint('small only')} {
		margin: ${DASHBOARD_HEADER_HEIGHT} 0 0 0;
		width:100%;
		flex-direction:column;
	}
`;
function useQuery() {
	return new URLSearchParams(useLocation().search);
}
export const Dashboard = props => {
	const query = useQuery()
	const accessToken = query.get('access_token')
	if (accessToken){
		set_cookie(ACCESS_TOKEN, accessToken);
	}

	useEffect(()=>{
		window.scrollTo({top: 0, behavior: 'smooth'});
	},[]);
	return (
		<PageWrapper width='100%' className='page-wrapper'>
			<DashboardHeader className='dashboard-container' />
			<PageContent>
				<MakePaymentBox className='make-payment-box' />
				<DashboardCard />

			</PageContent>
		</PageWrapper>
	);
};
