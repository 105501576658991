import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { Color } from '../../constants/style-constants';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const PageControllerView = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 24px;
	padding-top: 8px;
`;

const NonSelectedPage = styled(Button)``;

NonSelectedPage.defaultProps = {
	color: Color.black,
	width: '25px',
	height: '26px',
	paddingLeft: '0px',
	paddingRight: '0px',
	fontSize: '16px',
	textAlign: 'center',
	lineHeight: 18/16,
};

const SelectedPage = styled(Button)``;

SelectedPage.defaultProps = {
	backgroundColor: Color.black,
	color: Color.white,
	width: '25px',
	height: '26px',
	paddingLeft: '0px',
	paddingRight: '0px',
	borderRadius:'4px',
	fontSize: '16px',
	textAlign: 'center',
	lineHeight: 18/16
};

export const PageController = (props) => {
	const [offsetHigh, setOffsetHigh] = useState(0);
	const [offsetLow, setOffsetLow] = useState(0);

	const pageDown = () => {
		props.setPageNumber(props.pageNumber - 1);
		setOffsetHigh(0);
		setOffsetLow(0);
	};

	const pageUp = () => {
		props.setPageNumber(props.pageNumber + 1);
		setOffsetHigh(0);
		setOffsetLow(0);
	};

	const goToPage = (page) => {
		props.setPageNumber(page);
		setOffsetHigh(0);
		setOffsetLow(0);
	};

	let extraButtonsHigh=[];
	for(let i = 1; i <= offsetHigh; i++){
		extraButtonsHigh.push(<NonSelectedPage onClick={e => goToPage(props.pageNumber + 2 + i)} title={props.pageNumber + 3 + i} key={i} />);
	}

	let extraButtonsLow=[];
	for(let i = 1; i <= offsetLow; i++){
		extraButtonsLow.unshift(<NonSelectedPage onClick={e => goToPage(props.pageNumber - 2 - i)} title={props.pageNumber - 1 - i} key={i} />);
	}
	return (
		<>
			{props.numPages !== 0 ?
				<PageControllerView>
					{props.pageNumber !== 1 && <Button onClick={pageDown} icon={faChevronLeft} />}
					{props.pageNumber - offsetLow > 2 && <NonSelectedPage  onClick={e => setOffsetLow(offsetLow + 1)} title="..." />}
					{extraButtonsLow}

					{props.pageNumber > 2 && <NonSelectedPage onClick={e => goToPage(props.pageNumber - 2)} title={props.pageNumber - 2} />}
					{props.pageNumber > 1 && <NonSelectedPage onClick={e => goToPage(props.pageNumber - 1)} title={props.pageNumber - 1} />}
					<SelectedPage title={props.pageNumber} />
					{props.pageNumber !== props.numPages && <NonSelectedPage onClick={e => goToPage(props.pageNumber + 1)} title={props.pageNumber + 1} />}
					{props.pageNumber < props.numPages - 1 && <NonSelectedPage onClick={e => goToPage(props.pageNumber + 2)} title={props.pageNumber + 2} />}

					{extraButtonsHigh}
					{props.pageNumber + offsetHigh < props.numPages - 2 && <NonSelectedPage  onClick={e => setOffsetHigh(offsetHigh + 1)} title="..." />}
					{props.pageNumber !== props.numPages && <Button onClick={pageUp} icon={faChevronRight} />}
				</PageControllerView>
				:
				<PageControllerView>
					<SelectedPage title={1} />
				</PageControllerView>
			}
		</>
	);
};
