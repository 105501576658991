import styled, { css } from 'styled-components';
export const PageWrapper = styled.div`
	${props => css`
		width:${props.width?props.width:'100%'};
		height:${props.height?props.height:'100%'};
		min-height:${props.minHeight?props.minHeight:'100vh'};
		margin-left: ${props.marginLeft};
		margin-right: ${props.marginRight};
		padding-left: ${props.paddingLeft};
		padding-right: ${props.paddingRight};
	`}
`;