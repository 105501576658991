import styled, { css } from 'styled-components';
import { Color, FontWeight } from '../../constants/style-constants';

// Label: General component used to display an empty view or label.
// Props:
// all StyledBase props are available
// horizontalAlign: aligns text horizontally. values:left (default), center and right.
// verticalAlign: aligns text vertically, values: center (default), top, bottom.
export const StyledText = styled.p`
	${(props) => css`
		overflow-wrap: ${props.overflowWrap};
		float: ${props.float};
		width: ${props.width};
		height: ${props.height};
		min-width: ${props.minWidth};
		min-height: ${props.minHeight};
		color: ${props.color};
		cursor: ${props.cursor};
		background-color: ${props.backgroundColor};
		line-height: ${props.lineHeight};
		font-size: ${props.fontSize};
		font-weight: ${props.fontWeight};
		font-family: ${props.fontFamily};
		text-decoration: ${props.textDecorationLine};
		text-decoration-color: ${props.textDecorationColor};
		text-decoration-style: ${props.textDecorationStyle};
		border: ${props.border};
		border-bottom: ${props.borderBottom};
		border-top: ${props.borderTop};
		border-left: ${props.borderLeft};
		border-right: ${props.borderRight};
		border-radius: ${props.borderRadius};
		border-width: ${props.borderWidth};
		border-color: ${props.borderColor};
		border-style: ${props.borderStyle};
		padding:${props.padding};
		padding-top: ${props.paddingTop};
		padding-right: ${props.paddingRight};
		padding-bottom: ${props.paddingBottom};
		padding-left: ${props.paddingLeft};
		margin:${props.margin};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		box-shadow: ${props.boxShadow};
		z-index: ${props.zIndex};
		text-align: ${props.horizontalAlign};
		font-style: ${props.fontStyle};
		text-transform: ${props.textTransform};
		white-space: ${props.whiteSpace};
		display:${props.display};
		justify-content: ${props.horizontalAlign === 'left'
			? 'flex-start'
			: props.horizontalAlign === 'right'
				? 'flex-end'
				: 'center'};

		align-items: ${props.verticalAlign === 'top'
			? 'flex-start'
			: props.verticalAlign === 'bottom'
				? 'flex-end'
				: 'center'};
		flex-grow: ${props.flexGrow};
		flex-shrink: ${props.flexShrink};

		&:hover,
		&:focus,
		&:active {
			transform: ${props.hoverTransform};
			filter: ${props.hoverFilter};
			box-shadow: ${props.hoverBoxShadow};
			background-color: ${props.hoverBackground};
			color: ${props.hoverColor};
			border-color: ${props.hoverBorderColor};
			border: ${props.border};
			border-bottom: ${props.hoverBorderBottom};
			border-top: ${props.hoverBorderTop};
			border-left: ${props.hoverBorderLeft};
			border-right: ${props.hoverBorderRight};
			border-radius: ${props.hoverBorderRadius};
			border-width: ${props.hoverBorderWidth};
			border-color: ${props.hoverBorderColor};
			border-style: ${props.hoverBorderStyle};
			text-decoration: ${props.hoverTextDecorationLine};
			text-decoration-color: ${props.hoverTextDecorationColor};
			text-decoration-style: ${props.hoverTextDecorationStyle};
			opacity: ${props.hoverOpacity};
		}
	`}

`;

StyledText.defaultProps = {
	display: 'flex',
	flexShrink: 1,
	whiteSpace: 'pre-wrap',
	horizontalAlign: 'left',
	verticalAlign: 'center',
	margin: '0px',
};
export const InputLabelText = styled(StyledText)``;
InputLabelText.defaultProps = {
	color: Color.liunaBlack,
	fontWeight: FontWeight.semibold,
	lineHeight: 17 / 16,
	marginBottom:'4px',
	fontSize: '16px'
};

export const FormTitleText = styled(StyledText)``;
FormTitleText.defaultProps = {
	color: Color.signUpTitleBlack,
	fontWeight: FontWeight.bold,
	fontSize: '50px',
	lineHeight: 55 / 50
};

export const DashboardTableTitle = styled(StyledText)``;
DashboardTableTitle.defaultProps = {
	color: Color.formTitleBlack,
	fontWeight: FontWeight.bold,
	fontSize: '28px',
	lineHeight: 36 / 28,
	margin: '32px',
};

export const TableColumnText = styled(StyledText)``;
TableColumnText.defaultProps = {
	color: Color.steelix,
	fontWeight: FontWeight.normal,
	fontSize: '14px',
	lineHeight: 18 / 14
};

export const DashBoardHeaderText = styled(StyledText)``;
DashBoardHeaderText.defaultProps = {
	color: Color.white,
	fontWeight: FontWeight.bold,
	fontSize: '16px',
	lineHeight: 18 / 16
};

export const DashedUnderLineText = styled(StyledText)``;
DashedUnderLineText.defaultProps = {
	color: Color.mamba,
	fontWeight: FontWeight.normal,
	fontSize: '16px',
	lineHeight: 20 / 16,
	borderBottom: `1px dashed ${Color.steelix}`,
	hoverBorderBottom: `1px solid ${Color.steelix}`,
	display: 'inline-block',
};
export const DueText = styled(StyledText)``;
DueText.defaultProps = {
	color: Color.mamba,
	fontWeight: FontWeight.normal,
	fontSize: '16px',
	lineHeight: 20 / 16,
	display: 'inline-block',
};

export const DueTextValue = styled(StyledText)``;
DueTextValue.defaultProps = {
	color: Color.mamba,
	fontWeight: FontWeight.bold,
	fontSize: '21px',
	lineHeight: 27 / 21,
	display: 'inline-block',
};

export const SteelixText = styled(StyledText)``;
SteelixText.defaultProps = {
	color: Color.steelix,
	fontWeight: FontWeight.normal,
	fontSize: '16px',
	lineHeight: 20 / 16,
	display: 'block',
};

export const SteelixTextBold = styled(StyledText)``;
SteelixTextBold.defaultProps = {
	color: Color.steelix,
	fontWeight: FontWeight.bold,
	fontSize: '21px',
	lineHeight: 27 / 21,
	display: 'inline-block',
};
