import React from 'react';
import { FormTitleText, InputLabelText } from '../Shared/StyledText';
import { FormInput } from '../Shared/Input';
import { SubmitButton, UnderlineButton } from '../Shared/Button';
import { useHistory } from 'react-router-dom';
import { NAV_LOGIN } from '../../constants/nav-constants';
export const SignUpFormRender = props =>{
	const history = useHistory();
	return (<>
		<FormTitleText marginBottom='32px'>Sign-up</FormTitleText>
		<FormInput onChange={props.handleInputChange} inputId='username' height='48px' label={<InputLabelText>Email Address*</InputLabelText>}/>
		<FormInput onChange={props.handleInputChange} inputId='union_number' height='48px' label={<InputLabelText>Union Number*</InputLabelText>}/>
		<FormInput onChange={props.handleInputChange} inputType='password' marginBottom='32px' inputId='password'  height='48px' label={<InputLabelText>Password*</InputLabelText>}/>
		<SubmitButton width='100%' marginBottom='32px' height='50px' title='submit'/>
		<UnderlineButton onClick={e=>{e.preventDefault();history.push(NAV_LOGIN);}} marginBottom='32px'title='login'/>
	</>);
};