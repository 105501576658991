import React, { useState } from 'react';
import { BackButton } from '../Shared/Button';
import { useHistory } from 'react-router-dom';
import { NAV_DASHBOARD } from '../../constants/nav-constants';
import { PageWrapper } from '../Shared/Containers/PageWrapper';
import { DashboardHeader } from '../Dashboard/DashboardHeader';
import { PageContent } from '../Dashboard/Dashboard';
import styled from 'styled-components';
import { CardWrapper } from '../Dashboard/DashboardCard';
import { DashboardTableTitle, SteelixText } from '../Shared/StyledText';
import { MakePaymentForm } from './MakePaymentForm';
import { PaymentSummary } from './PaymentSummary';
import { breakpoint } from '../../projlibs/breakpoints';
import { Color } from '../../constants/style-constants';
import { useEffect } from 'react';
import { TransactionError } from './TransactionError';
import { PaymentSuccess } from './PaymentSuccess';
const Li = styled.li`
	list-style-type: none;
`;
export const LeftWrapper = styled.div`
	box-sizing: border-box;
	width:70%;
	display:inline-block;
	padding:16px 16px 16px 0;
	${breakpoint('small only')}{
		width: 100%;
	}
`;
const RightWrapper = styled.div`
	box-sizing: border-box;
	width:30%;
	display:inline-block;
	padding:0 0 16px 16px;
	border-left: 1px solid ${Color.inputBorder};
	${breakpoint('small only')}{
		width: 100%;
		border-left:none;
		padding:0;
	}
`;
const FormWrapper = styled.div`
	display:flex;
	${breakpoint('small only')}{
		flex-direction:column-reverse;
	}
`;


export const MakePayment = props => {
	const history = useHistory();
	const [amountToPay, setAmountToPay] = useState(0);
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [liunaError, setLiunaError] = useState(false);
	const [errorInfo, setErrorInfo] = useState({});
	const [duplicateTsx, setDuplicateTsx] = useState(false)
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	const valueChange = (values) => {
		const { floatValue } = values;
		let val = floatValue;
		if (isNaN(floatValue)) {
			val = 0;
		}
		setAmountToPay(val);
	};

	return (
		<PageWrapper className='page-wrapper'>
			<DashboardHeader className='dashboard-container' />
			<PageContent>
				<CardWrapper marginBottom='32px' padding='16px' width='100%'>
					<BackButton onClick={e => { e.preventDefault(); history.push(NAV_DASHBOARD); }} />
					{paymentSuccess ? <PaymentSuccess /> : <>
						{(!duplicateTsx && !liunaError) &&
							<DashboardTableTitle margin='32px 0 32px 0' padding='0' fontSize='38px' lineHeight={47 / 38}>Make a payment</DashboardTableTitle>
						}
						<FormWrapper>
							{duplicateTsx && <TransactionError
								title="Your transaction has already successfully processed."
								description={
									<SteelixText horizontalAlign="center" fontSize="24px">
										Please check your email for the receipt. If you do not have a receipt, please contact support.
										<ul>
											<Li>
												<SteelixText>Order number: {errorInfo?.order_number}</SteelixText>

											</Li>
											{errorInfo.amount &&
												<Li>
													<SteelixText>Order amount: ${parseInt(errorInfo?.amount)?.toFixed(2)} CAD</SteelixText>

												</Li>
											}
										</ul>
									</SteelixText>
								}
							/>}
							{liunaError && <TransactionError
								title="You were successfully charged, but an error occurred."
								description={
									<SteelixText horizontalAlign="center" fontSize="24px">LiUNA Dues System Error - please contact support with the following information.<ul>
										<Li>
											<SteelixText>Transaction time: {errorInfo?.created_at}</SteelixText>
										</Li>
										<Li>
											<SteelixText>Order number: {errorInfo?.order_number}</SteelixText>

										</Li>
										{errorInfo.amount &&
											<Li>

												<SteelixText>Order amount: ${errorInfo?.amount?.toFixed(2)} CAD</SteelixText>
											</Li>
										}
									</ul>
									</SteelixText>
								}
							/>}
							{(!duplicateTsx && !liunaError) &&
								<>
									<LeftWrapper>
										<MakePaymentForm setPaymentSuccess={setPaymentSuccess} setErrorInfo={setErrorInfo} loading={loading} setLoading={setLoading} setDuplicateTsx={setDuplicateTsx} duplicateTsx={duplicateTsx} liunaError={liunaError} setLiunaError={setLiunaError} amountToPay={amountToPay} />
									</LeftWrapper>
									<RightWrapper>
										<PaymentSummary loading={loading} amount={amountToPay} valueChange={valueChange} />
									</RightWrapper>
								</>
							}
						</FormWrapper>
					</>}
				</CardWrapper>
			</PageContent>
		</PageWrapper>
	);
};
