import React from 'react';

//The Data Model State is stored as a React Context to avoid having to pass it down manually to everything which uses it
//NOTE: this could also be done with Redux
//initially we set blank, null, or other sane default values but these will be overwritten as data from network calls is received
const DataContext = React.createContext({
	userSessionId: null,
	access_token: null,
	payments: [],
	setBamboraInputs: ()=>{},
	bamboraInputs:[]
	
	
});


//displayName is used internally by React Context for dev tools views
//to make debugging easier
DataContext.displayName = 'YOUnion Data Context';

export { DataContext };