import React from 'react';
import styled from 'styled-components';
import { StyledText, SteelixText } from './Shared/StyledText';

const Wrapper = styled.div`
	width:100vw;
	background: linear-gradient(270deg, #1E3131 0%, #001414 100%);
	height:150px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`;
export const Footer = props =>{
	return(
		<Wrapper>
			<SteelixText fontSize='14px' lineHeight={18 / 14}>LiUNA Local 1059</SteelixText>
			<SteelixText fontSize='14px' lineHeight={18 / 14}>635 Wilton Grove Road, London ON, N6N 1N7</SteelixText>
			<SteelixText fontSize='14px' lineHeight={18 / 14}>T: (519) 455-8083</SteelixText>
			<SteelixText fontSize='14px' lineHeight={18 / 14}>F: (519) 455-0712</SteelixText>
			<StyledText
				as='a'
				href={'http://liuna1059.wpengine.com/dues-policy/'}
				color='white'
				fontSize='14px' lineHeight={18 / 14}
				textDecorationLine='underline'>
				Dues Policy
			</StyledText>
		</Wrapper>
	);
};